<script>
    import { link } from 'svelte-routing';
    // -- IMPORTS

    import { getImagePath } from '$lib/filePath';
    import { websiteUrl } from '$lib/base.js';
    import Seo from '$lib/component/element/Seo.svelte';
    import 'leaflet.markercluster/dist/MarkerCluster.css';
    import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
    import 'leaflet/dist/leaflet.css';
    import { enabledAdminView } from '$lib/store/adminStore';
    import { getKebabCaseString } from '$lib/textUtils';
    import { getLocalizedText } from 'senselogic-gist';
    import { languageTagStore } from '$lib/store/languageTagStore';
    import { textStore } from '$lib/store/textStore';
    import AdminEditButton from '$lib/component/element/AdminEditButton.svelte';
    import AnimatedContainer from '$lib/component/element/AnimatedContainer.svelte';
    import AnimatedText from '$lib/component/element/AnimatedText.svelte';
    import { fetchData } from '../base';
    import { pageStore } from '../store/pageDataStore';
    import { languageArrayStore } from '../store/languageArrayStore';
    import urlStore from '../store/urlStore';
    import Carousel from '../component/element/carousel.svelte';
    import FullLoading from '../component/Layout/FullLoading.svelte';

    // -- VARIABLES

    export let slug;
    let isLoading = true;

    let page = $pageStore.page.hubPage[ slug ];
    let hub = $pageStore.block.hub[ slug ];
    let blockArray =  $pageStore.block.hubBlockArray[ slug ];
    let hubArray = $pageStore.block.hubArray;

    let leaflet;
    let map;
    let metaTitle = '';
    let metaDescription = '';
    let metaKeywords = '';
    let imagePath = '';
    let url = '';

    let carouselConfigPerPage =
    {
        390 : 1,
        768 : 2,
        1280 : 2,
        1920 : 2,
        3840 : 2
    }

    // -- FUNCTIONS

    async function loadData(
        )
    {
        try
        {
            isLoading = true

            let [ pageData, hubsArrayData, hubData ] = await Promise.all(
                [
                    fetchData(
                        '/api/page/get-by-slug',
                        {
                            method: 'POST',
                            body: JSON.stringify(
                                {
                                    slug: slug
                                }
                            ),
                            headers: { 'Content-Type': 'application/json' }
                        }
                    ),
                    fetchData(
                        '/api/hub',
                        {
                            method: 'POST',
                            body: JSON.stringify(
                                {
                                    type: 'getHubArray',
                                }
                            ),
                            headers: { 'Content-Type': 'application/json' }
                        }
                    ),
                    fetchData(
                        '/api/hub/get-by-slug',
                        {
                            method: 'POST',
                            body:  JSON.stringify(
                                {
                                    slug: slug
                                }
                            ),
                            headers: { 'Content-Type': 'application/json' }
                        }
                    )
                ]
            )

            let hubBlockArray
                = await fetchData(
                    '/api/block/get-by-page-id',
                    {
                        method: 'POST',
                        body: JSON.stringify(
                            {
                                pageId: pageData.page.id
                            }
                            ),
                        headers: { 'Content-Type': 'application/json' }
                    }
                    );

            $pageStore.page.hubPage[ slug ] = pageData.page;
            $pageStore.block.hub[ slug ] = hubData.hub;
            $pageStore.block.hubBlockArray[ slug ] = hubBlockArray.blockArray;
            $pageStore.block.hubArray = hubsArrayData.hubArray;

            page = pageData.page;
            hub = hubData.hub;
            blockArray = hubBlockArray.blockArray
            hubArray = hubsArrayData.hubArray;

            metaTitle = pageData.page.metaTitle ?? pageData.page.title;
            metaDescription = pageData.page.metaDescription ?? pageData.page.description;
            metaKeywords = pageData.page.metaKeywords;
            imagePath = pageData.page.imagePath;
            url = websiteUrl;
        }
        catch ( error )
        {
            console.error( 'Error:', error )
        }
        finally
        {
            isLoading = false;
        }
    }

    // ~~

    async function initializeMap(
        container
        )
    {
        leaflet = await import( 'leaflet' );

        map = leaflet.map( container,
            {
                scrollWheelZoom: false,
                attributionControl: false,
                maxZoom: 18,
                minZoom: 10,
            }
            ).setView( [ hub.latitude, hub.longitude ], 18 );

        await import( 'leaflet.markercluster/dist/leaflet.markercluster-src.js' );

        // L is a variable provided by Leaflet.

        let customIcon = L.icon(
            {
                iconUrl: '/image/icon/map_pin.svg',
                iconSize: [38, 95],
                iconAnchor: [22, 94],
                popupAnchor: [-3, -76],
            }
            );

        let marker = L
            .marker( [ hub.latitude, hub.longitude ], { icon: customIcon } );

        leaflet.tileLayer(
            'https://api.mapbox.com/styles/v1/fcfabiano/clvfckdmm057z01pk63kocnzx/tiles/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZmNmYWJpYW5vIiwiYSI6ImNsdmY4bHIxZTBiNDQyaW4wMmpjanRnY2sifQ.QF-FqB8dgjNk1JRDDu3nfQ',
            {
                attribution: '© OpenStreetMap contributors'
            }
            )
            .addTo( map );

        map.on('moveend', function() {
    }
    );

        marker.addTo( map );
    }

    // ~~

    function resizeMap(
        )
    {
        if ( map )
        {
            map.invalidateSize();
        }
    }

    // ~~

    function mapAction(
        container
        )
    {
        initializeMap( container );
    }

    // ~~

    async function mountComponent(
        slug
        )
    {
        isLoading = true;

        if
        (
            page === undefined
            || hubArray === undefined
            || hub === undefined
            || blockArray === undefined
            || $pageStore.page.hubPage[ slug ] === undefined
            || $pageStore.block.hub[ slug ] === undefined
            || $pageStore.block.hubBlockArray[ slug ] === undefined
        )
        {
            await loadData()
        }
        else
        {
            page = $pageStore.page.hubPage[ slug ];
            hub = $pageStore.block.hub[ slug ];
            blockArray =  $pageStore.block.hubBlockArray[ slug ];
            hubArray = $pageStore.block.hubArray;
            isLoading = false;
        }

        isLoading = false;
    }

    // -- STATEMENTS

    $:  mountComponent( slug )

    $: if ( !isLoading && $urlStore.pathname.includes( `/hub/${ slug }` ) )
        {
            document.title = `Hub - ${ getLocalizedText( hub.name ) }`;
        }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../constant.styl';
    @import '../../mixin.styl';

    // -- CLASSES

    .hub-section
    {
        width: 100%;
        padding: 5rem 0;

        display: flex;
        flex-direction: column;
        gap: 5rem;
        align-items: center;
        align-self: stretch;
    }

    .hub-title
    {
        padding: 0 1.5rem;

        line-height: 2.5rem;
        font-size: 2rem;
        letter-spacing: 0.16rem;
        text-transform: uppercase;

        +media( desktop )
        {
            line-height: 7.5rem;
            font-size: 6rem;
            font-weight: 250;
            letter-spacing: 0.48rem;
            text-align: center;
        }
    }

    .hub-description
    {
        padding: 0 1.5rem;

        line-height: 2.09rem;
        font-size: 1.1rem;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.2px;
        text-align: left;

        +media( desktop )
        {
            max-width: 77vw;

            line-height: 2.25rem;
            font-size: 1.5rem;
            text-align: center;
        }
    }

    .hub-block-list
    {
        padding: 0 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 4rem;
        align-items: center;

        +media( desktop )
        {
            max-width: 77vw;

            gap: 5rem;
        }
    }

    .hub-image-container
    {
        width: 100%;
    }

    .hub-image-container .hub-img
    {
        height: 32.23rem;
        width: 100%;

        object-fit: cover;

        +media( desktop )
        {
            height: 44.875rem;
        }
    }

    .hub-block-item.text-and-image
    {
        display: flex;
        flex-direction: column;
        align-items: center;

        +media( desktop )
        {
            max-width: 85vw;
        }
    }

    .hub-block-item.text-and-image >div
    {
        margin-top: 1rem;
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        align-items: center;

        +media( desktop )
        {
            width: 80vw;
        }
    }

    .hub-block-item.grid
    {
        +media( desktop )
        {
            max-width: 85vw;
        }
    }

    .hub-block-item.grid >div
    {
        margin-top: 4rem;

        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;

        +media( desktop )
        {
            margin-top: 5rem;
            max-width: 85vw;

            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 4rem;
        }
    }

    .hub-block-title
    {
        width: 100%;

        font-size: 2rem;
        letter-spacing: 0.16rem;
        text-align: left;
        text-transform: uppercase;

        +media( desktop )
        {
            line-height: 7.5rem;
            font-size: 6rem;
            text-align: center;
        }
    }

    .hub-block-description
    {
        margin-top: 1rem;

        line-height: 2.25rem;
        font-size: 1.5rem;
        font-weight: 400;
        font-style: normal;
        text-align: left;

        +media( desktop )
        {
            margin-top: 0.5rem;

            text-align: center;
        }
    }

    .hub-sub-block-item.text-and-image
    {
        width: 100%;

        display: flex;
        flex-direction: column-reverse;

        +media( desktop )
        {
            max-width: 77vw;

            flex-direction: row;
            gap: 5rem;
        }
    }

    .hub-sub-block-item.text-and-image.left
    {
        +media( desktop )
        {
            flex-direction: row-reverse;
        }
    }

    .hub-sub-block-item.text-and-image >div
    {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
    }

    .hub-sub-block-item.text-and-image >div:first-child
    {
        margin-top: 2.5rem;
    }

    .hub-sub-block-title
    {
        display: none;

        +media( desktop )
        {
            margin-bottom: 2rem;

            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            align-items: center;
        }
    }

    .hub-sub-block-title.mobile
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0;

        +media( desktop )
        {
            display: none;
        }
    }

    .hub-sub-block-title > .sub-block-title-img
    {
        height: 3rem;
    }

    .hub-sub-block-title >p
    {
        line-height: 2rem;
        font-size: 1.4375rem;
        font-weight: 500;
        letter-spacing: 0.08625rem;
        text-transform: uppercase;

        +media( desktop )
        {
            line-height: 2.5rem;
            font-size: 2rem;
            letter-spacing: 0.16rem;
        }
    }

    .hub-sub-block-text
    {
        margin-bottom: 1rem;

        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-items: center;
    }

    .hub-sub-block-text > .hub-sub-block-img
    {
        height: 2.5rem;
    }

    .hub-sub-block-text p
    {
        line-height: 2rem;
        font-size: 1.25rem;
        letter-spacing: 0.03rem;

        +media( desktop )
        {
            line-height: 2.25rem;
            font-size: 1.5rem;
        }
    }

    .hub-sub-block-image
    {
        +media( desktop )
        {
            // padding: 0 1rem;
        }
    }

    .hub-sub-block-image .sub-block-img
    {
        height: 18.75rem;
        width: 100%
        aspect-ratio: 0.5;

        object-fit: cover;

        +media( desktop )
        {
            height: 40rem;
        }
    }

    .hub-sub-block-item.grid
    {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;

        +media( desktop )
        {
            align-items: flex-start;
        }
    }

    .hub-sub-block-item.grid > .sub-block-grid-img
    {
        height: 2.5rem;
        width: 2.5rem;

        +media( desktop )
        {
            height: 3rem;
            width: 3rem;
        }
    }

    .hub-sub-block-item.grid >p
    {
        line-height: 2rem;
        font-size: 1.25rem;
        font-weight: 400;
        font-style: normal;
    }

    .map
    {
        width: 100%;
        aspect-ratio: 3/3;
        margin-bottom: 3.5rem;

        filter: sepia(30%);
        
        +media( desktop )
        {
            height: 42.8125rem;
        }
    }

    .hub-sub-block-item.carousel
    {
        margin: auto;
        width: 18.75rem;

        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;

        +media( desktop )
        {
            width: 26.625rem;
        }
    }

    .hub-sub-block-item-image.carousel
    {
        height: 18.75rem;

        align-self: stretch;
        object-fit: cover;

        +media( desktop )
        {
            height: 28.75rem;
        }
    }

    .hub-sub-block-item-content.carousel
    {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-items: flex-start;
        align-self: stretch;
    }

    .other-hub
    {
        margin-bottom: 5rem;
    }

    .text-center
    {
        text-align: center;
    }

    .anchor-button
    {
        width: 100%;
        min-width: 10.6125rem;
        border: 1px solid lightGoldColor;
        border-radius: 0;
        padding: 0.75rem 1.25rem;

        background-color: darkGreyColor;

        text-align: center;
        text-wrap: nowrap;

        font-size: 1rem;
        text-transform: uppercase;
        color: lightGoldColor;

        cursor: pointer;
        transition: all 0.2s ease-in-out;

        +media( smaller-desktop )
        {
            width: 100%;
            max-width: unset;
        }
    }

    .anchor-button.invert-color
    {
        border: 1px solid lightGoldColor;

        background: lightGoldColor;

        color: darkGreyColor;
    }

    .anchor-button:hover,
    .anchor-button:focus
    {
        background-color: lightGoldColor;

        color: darkGreyColor;
    }

    .anchor-button.invert-color:hover,
    .anchor-button.invert-color:focus
    {
        background-color: darkGreyColor;

        color: lightGoldColor;
    }
</style>

<svelte:window on:resize={ resizeMap }/>

{#key $urlStore.pathname }
    {#if isLoading }
        <FullLoading />
    {:else}
        <Seo
            metaTitle={ metaTitle }
            metaDescription={ metaDescription }
            metaKeywords={ metaKeywords }
            url={ url }
            imagePath={ imagePath }
            languageData={ $languageArrayStore }
            path=''
        />

        <section class="hub-section" class:is-admin-enabled={ $enabledAdminView }>
            <AdminEditButton
                type="page"
                id={ page.id }
                inset="2rem 10rem auto auto"
            />
            {#if page.title }
                <p class="hub-title color-light-gold">
                    <AnimatedText text={ getLocalizedText( page.title, $languageTagStore ) }/>
                </p>
            {/if}
            {#if page.imagePath }
                <div class="hub-image-container">
                    <AnimatedContainer>
                        <div
                            class="hub-img"
                            style="
                                background: URL({getImagePath( page.imagePath, '1920' )}) no-repeat center center / cover,
                                            URL({getImagePath( page.imagePath, '360' )}) no-repeat center center / cover;
                                "
                        />
                    </AnimatedContainer>
                </div>
            {/if}
            {#if page.description }
                <p class="hub-description color-light-grey">
                    <AnimatedText text={ getLocalizedText( page.description, $languageTagStore ) } />
                </p>
            {/if}
            <div class="hub-block-list">
                {#each blockArray as hubBlock }
                    <div class="hub-block-item { hubBlock.typeSlug }"class:is-admin-enabled={ $enabledAdminView }>
                        <AdminEditButton
                            type="block"
                            id={ hubBlock.id }
                        />
                        {#if hubBlock.title }
                            <p class="hub-block-title color-light-gold">
                                <AnimatedText text={ getLocalizedText( hubBlock.title, $languageTagStore ) } />
                            </p>
                        {/if}
                        {#if hubBlock.text }
                            <p class="hub-block-description color-light-grey">
                                <AnimatedText text={ getLocalizedText( hubBlock.text, $languageTagStore ) } />
                            </p>
                        {/if}

                        {#if hubBlock.typeSlug === 'text-and-image' }
                            <div>
                                {#each hubBlock.textArray as subBlock }
                                    {#if subBlock.title }
                                        <AnimatedContainer>
                                            <div class="hub-sub-block-title mobile">
                                                {#if subBlock.iconPath }
                                                    <div
                                                        class="hub-sub-block-img"
                                                        style="
                                                            background: URL({getImagePath( subBlock.iconPath, '1920' )}) no-repeat center center / cover,
                                                                        URL({getImagePath( subBlock.iconPath, '360' )}) no-repeat center center / cover;
                                                            "
                                                    />
                                                {/if}
                                                {#if subBlock.title }
                                                    <p class="color-light-gold text-center">{ getLocalizedText( subBlock.title, $languageTagStore ) }</p>
                                                {/if}
                                            </div>
                                        </AnimatedContainer>
                                    {/if}
                                    <div class="hub-sub-block-item text-and-image { subBlock.imageSide }">
                                        <div class="hub-sub-block-title-text">
                                            {#if subBlock.title }
                                                <AnimatedContainer>
                                                    <div class="hub-sub-block-title" style="margin-top: 0;">
                                                        {#if subBlock.iconPath }
                                                            <div
                                                                class="sub-block-title-img"
                                                                style="
                                                                    background: URL({getImagePath( subBlock.iconPath, '1920' )}) no-repeat center center / cover,
                                                                                URL({getImagePath( subBlock.iconPath, '360' )}) no-repeat center center / cover;
                                                                    "
                                                            />
                                                        {/if}
                                                        {#if subBlock.title }
                                                            <p class="color-light-gold">{ getLocalizedText( subBlock.title, $languageTagStore ) }</p>
                                                        {/if}
                                                    </div>
                                                </AnimatedContainer>
                                            {/if}
                                            {#if subBlock.textArray }
                                                {#if subBlock.textArray.length > 0 }
                                                    {#each subBlock.textArray as subBlockText }
                                                        <AnimatedContainer>
                                                            <div class="hub-sub-block-text">
                                                                <img src="/image/icon/check.svg" alt=""/>
                                                                {#if subBlockText.text }
                                                                    <p class="color-light-grey">{ getLocalizedText( subBlockText.text, $languageTagStore ) }</p>
                                                                {/if}
                                                            </div>
                                                        </AnimatedContainer>
                                                    {/each}
                                                {/if}
                                            {/if}
                                        </div>
                                        <div class="hub-sub-block-image">
                                            <AnimatedContainer>
                                                {#if subBlock.imagePath }
                                                    <div
                                                        class="sub-block-img"
                                                        style="
                                                            background: URL({getImagePath( subBlock.imagePath, '1920' )}) no-repeat center center / cover,
                                                                        URL({getImagePath( subBlock.imagePath, '360' )}) no-repeat center center / cover;
                                                            "
                                                    />
                                                {/if}
                                            </AnimatedContainer>
                                        </div>
                                    </div>
                                {/each}
                            </div>
                        {:else if hubBlock.typeSlug === 'grid' }
                            <div>
                                {#each hubBlock.textArray as subBlock }
                                    <AnimatedContainer>
                                        {#if subBlock.iconPath && subBlock.description }
                                            <div class="hub-sub-block-item grid">
                                                {#if subBlock.iconPath }
                                                    <div
                                                        class="sub-block-grid-img"
                                                        style="
                                                            background: URL( { getImagePath( subBlock.iconPath, '1920' ) } ) no-repeat center center / cover,
                                                                        URL( { getImagePath( subBlock.iconPath, '360' ) } ) no-repeat center center / cover;
                                                            "
                                                    />
                                                {/if}
                                                {#if subBlock.description }
                                                    <p class="color-light-grey">{ getLocalizedText( subBlock.description, $languageTagStore ) }</p><br>
                                                {/if}
                                            </div>
                                        {/if}
                                    </AnimatedContainer>
                                {/each}
                            </div>
                        {/if}
                    </div>
                {/each}
            </div>
        </section>

        {#if hub.latitude && hub.longitude }
            <div class="map" use:mapAction/>
        {/if}

        <div class="other-hub">
            <p class="hub-block-title color-light-gold text-center">
                <AnimatedText text={ getLocalizedText( $textStore[ 'other-premium-hubs-label' ] || '', $languageTagStore ) } />
            </p>
            <Carousel
                totalItemsLength={ hubArray.length }
                draggable={ true }
                hasCounter={ false }
                loop={ true }
                duration={ 300 }
                perPage={ carouselConfigPerPage }
                autoplay={ 3000 }
            >
                {#each hubArray as hubOption }
                    {#if hubOption.slug !== hub.slug }
                        <div class="hub-sub-block-item carousel">
                            <div
                                class="hub-sub-block-item-image carousel"
                                style="
                                    background: URL( { getImagePath( hubOption.imagePath, '1920' ) } ) no-repeat center center / cover,
                                                URL( { getImagePath( hubOption.imagePath, '360' ) } ) no-repeat center center / cover;
                                    "
                            />

                            <div class="hub-sub-block-item-content carousel">
                                <span class="font-size-150 font-weight-500 color-gray">{ getLocalizedText( hubOption.name, $languageTagStore ) }</span>
                                <a use:link class="anchor-button" href={ `${ getKebabCaseString( getLocalizedText( hubOption.slug, 'en' ) ) }`} aria-label="">
                                    { getLocalizedText( $textStore[ !hubOption.isActive ? 'hubs-come-soon-button' : 'hubs-see-more-button' ] || '', $languageTagStore ) }
                                </a>
                            </div>
                        </div>
                    {/if}
                {/each}
            </Carousel>
        </div>
    {/if}
{/key}
